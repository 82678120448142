import { createApp as createClientApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createRouter } from './router'
import VueroApp from './VueroApp.vue'
import './styles'
import '/@src/utils/axios'

import { createApi } from '/@src/composable/useApi'
import { createGtm } from '@gtm-support/vue-gtm'

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (vuero: VueroAppContext) => void | Promise<void>

const plugins = import.meta.glob<{ default: VueroPlugin }>('./plugins/*.ts', {
  eager: true,
})

// this is a helper function to define plugins with autocompletio
export function definePlugin(plugin: VueroPlugin) {
  return plugin
}

export async function createApp() {
  const app = createClientApp(VueroApp)
  const router = createRouter()
  const api = createApi()

  const head = createHead()
  app.use(head)

  const pinia = createPinia()
  app.use(pinia)

  function gtag(){
    window.dataLayer = window.dataLayer || [];
    const result= window.dataLayer.push(arguments);
    console.log('gtag', result, arguments);
  }

    gtag('consent', 'default', {
      'ad_storage': 'denied', //Google Ads
      'analytics_storage': 'denied', //Google Analytics
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'personalization_storage': 'denied', //e.g reccomendations based on previous browsing
      'functionality_storage': 'denied', //Functionality e.g Language selected
      'security_storage': 'denied', //Auth and Authentication
    });
  
    gtag('consent', 'update', {
      'ad_storage': 'granted', //Google Ads
      'analytics_storage': 'granted', //Google Analytics
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'personalization_storage': 'granted', //e.g reccomendations based on previous browsing
      'functionality_storage': 'granted', //Functionality e.g Language selected
      'security_storage': 'granted', //Auth and Authentication
    });

  app.use(
    createGtm({
      id: 'GTM-MN62S4Q',
      vueRouter: router,
    })
  )
  
  
  const vuero = {
    app,
    api,
    router,
    head,
    pinia,
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_KEY,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/hoovi\.ee/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  app.provide('vuero', vuero)

  for (const path in plugins) {
    try {
      const { default: plugin } = plugins[path]
      await plugin(vuero)
    } catch (error) {
      console.error(`Error while loading plugin "${path}".`)
      console.error(error)
    }
  }

  // use router after plugin registration, so we can register navigation guards
  app.use(vuero.router)

  return vuero
}
