import { _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/atlassian/pipelines/agent/build/src/pages/[...all].vue'),
    /* no children */
  }
]
